// Color
$primary: #28265E;
$secondary: #F3DA0F;
$text-muted: #aaafb4;
$border-color: #E5E5E5;
$info: #1CBBFF;
$danger: #FF184E;
$green: #56CA71;

// Body
$body-color: #333;
$body-bg: #fff;
$bg-white: white;
$bg-dark: #182232;
$bg-darker: #12131D;
// Typography
$font-size-base: 1rem;
$line-height-base: 1.5;

// Images
$aspect-ratio: calc(4 / 3);

// Spacing
$enable-negative-margins: true;

:root {
    --bs-body-bg: #fff;
    --bs-navbar-nav-link-padding-x: 0.5rem;
}
