.paywall {

    .box {
        position: relative;
        &.gradient-top:before {
            content: "";
            position: absolute;
            top: -110px;
            left: 0;
            width: 100%;
            height: 110px;
            background-image: linear-gradient(
                    to bottom,
                    rgba(255, 255, 255, .5),
                    white
            );
        }

        .paywall__content {
            min-height: 100vh;
            position: relative;
            width: 100%;
            border: 4px solid $primary;
            padding: 3rem;
            z-index: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;

            .paywall__content__title {
                font-style: normal;
                font-weight: 700;
                font-size: 1.8rem;
                line-height: 1.25;
                color: #252525;
                margin-bottom: 1rem;
            }

            .paywall__content__description {
                font-style: normal;
                font-weight: 500;
                font-size: 1.4rem;
                line-height: 1.25;
                color: #252525;
                margin-bottom: 1rem;
            }

            .paywall__content__cta {
                margin-top: 1rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                width: 100%;
                max-width: 400px;
                margin-left: auto;
                margin-right: auto;
                padding: 0 1rem;

                .paywall__content__cta__button {
                    width: 100%;
                    max-width: 400px;
                    margin-left: auto;
                    margin-right: auto;
                    padding: 0 1rem;
                }
            }

        }

    }
}
