.banner-section {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--bs-light);

    .banner-image {
        width: 970px;
        height: 250px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.banner-section-lg {
    display: flex;
    justify-content: center;
    align-items: center;

    .banner-image {
        width: 970px;
        height: 250px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
