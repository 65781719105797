@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Besley:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=DM+Serif+Display:ital@0;1&display=swap');

$font-family-sans: "DM Sans", -apple-system, "Helvetica Neue", Arial, sans-serif;
$font-family-serif: "Besley", "Times New Roman", serif;

.sans-light {
    font-family: $font-family-sans !important;
    font-weight: 300;
}

.sans-regular {
    font-family: $font-family-sans !important;
    font-weight: 400;
}

.sans-medium {
    font-family: $font-family-sans !important;
    font-weight: 500;
}

.sans-bold {
    font-family: $font-family-sans !important;
    font-weight: 700;
}

