.hero {
    .hero-image {
        height: unset;

        img {
            object-fit: contain;
            width: 100%;
            height: 100%;
            aspect-ratio: $aspect-ratio;
        }
    }

    .category-text {
        font-weight: 700;
    }

    .card-content {
        display: flex;
        flex-direction: column;

        .title-text {
            margin-top: auto;

            a {
                font-size: 42px;
                text-decoration: none;
                color: inherit;
                line-height: 1;
            }
        }

        .description-text {
            p {
                font-size: 16px;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }

        .author {
            font-size: 14px;
        }
    }
}

@media (max-width: 991.98px) {
    .hero {
        .title-text {
            a {
                font-size: 32px;
            }
        }
    }
}
