.events-detail {
    background-color: #dfdfdf;

    .event-detail-card {
        background-color: white;
        border-radius: 5px;

        .event-detail-card-header {
            span {
                font-size: 14px;
            }

            i {
                color: $danger;
            }
        }
    }

    .event-detail-tab {
        .nav-item {
            background-color: white;

            .nav-link {
                border-radius: 0;
                margin-bottom: 0;
                color: black;
                border-right: 1px solid $border-color !important;

                &.active {
                    background-color: $danger;
                    border-color: $danger;
                    color: white;
                    border-right: unset;
                }

                &:last-child {
                    border-right: unset;
                }
            }
        }
    }
}
