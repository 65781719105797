.header-menu {
    .nav-link {
        text-decoration: none;
        color: inherit;

        &:focus, &:hover {
            color: $primary;
        }
    }
}
