.account-section {
    .nav-pills {

        .nav-link {
            color: $primary;
            margin-right: 1rem;

            &:hover {
                background: $secondary;
                color: $body-color;
            }

            &.active {
                background: $primary;
                color: white;
            }
        }
    }
}
