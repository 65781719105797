.tags{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
    .tag{
        margin: 0 5px 5px;
        padding: 5px 10px;
        border-radius: 5px;
        background: $primary;
        border-color: $primary;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
        transition: all .3s ease;
        &:focus {
            box-shadow: none;
            border-color: #28265E;
            background-color: #28265E;
        }
        &:hover{
            background: $warning;
            border-collapse: $warning;
        }
    }
}
