.comments {
    .comment-title {
        font-size: 18px;
    }

    .comment-dots {
        font-size: 22px;
    }

    .comments-info {
        font-weight: lighter;
        font-size: 12px;
    }
}
