.news-card {
    a {
        text-decoration: none;
        color: inherit;
    }

    .news-card-title {
        @include line-clamp(2);
        font-size: 1.1rem !important;
        font-weight: 500;
        height: 3rem;
        margin-top: 0.5rem;
    }

    .news-card-image {
        position: relative;
        width: 100%;
        height: unset;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            aspect-ratio: $aspect-ratio;
        }

        .news-card-category {
            position: absolute;
            bottom: 0;
            left: 0;

            span {
                border-radius: 0;
            }
        }
    }

    .news-card-summary {
        font-size: 15px;
        @include line-clamp(2);
        height: 3rem;

        p {
            margin-bottom: 0;
        }
    }
}

.news-card-wide {
    border-radius: 5px;

    a {
        text-decoration: none;
        color: inherit;
    }

    .news-card-image {

        position: relative;

        img {
            width: 100%;
            object-fit: cover;
            aspect-ratio: $aspect-ratio;
        }

        .badge-default {
            position: absolute;
            bottom: -12px;
            left: -9px;

            &.badge-left-right {
                span {
                    border-top-left-radius: 4px !important;
                    border-bottom-right-radius: 4px !important;
                    border-bottom-left-radius: 0 !important;
                }
            }
        }
    }
}

.news-card-small {
    a {
        text-decoration: none;
        color: inherit;
    }

    .news-card-image {
        position: relative;

        img {
            width: 100%;
            object-fit: cover;
            aspect-ratio: $aspect-ratio;
        }
    }
    .news-card-headline {
        font-family: $font-family-serif;
        font-size: 0.9rem;
        font-weight: 500;
        line-height: 1.25;
        margin: 0;
        @include line-clamp(4);
        max-height: 5.12rem;
    }
}

.news-card-footer {

    .news-author {
        font-size: 12px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .user-image {
            width: 20px;
            height: 20px;

            img {
                width: 100%;
                height: 100%;
                aspect-ratio: 4/3;
            }
        }

        .author {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .splitter {
        display: inline-block;
        width: 1px;
        height: 12px;
        background-color: $text-muted;
        margin: 0 10px;
    }
}

.news-date {
    font-size: 12px;
    font-weight: bold;
    color: $text-muted;
    white-space: nowrap;
}
