.colored-title {
    position: relative;
    margin-left: 1rem;
    font-weight: 700;
    font-size: 1.25rem;

    &.small {
        font-size: 1rem;
    }


    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: -1rem;
        width: 0.25rem;
        height: 100%;
        background-color: $danger;
    }

    &:after {
        content: "";
        position: absolute;
        top: 0;
        right: -1rem;
        width: 0.25rem;
        height: 100%;
        background-color: $danger;
    }

    &.yellow {
        &:before {
            background-color: $warning;
        }

        &:after {
            background-color: $warning;
        }
    }
}


.discover-categories {
    .category-list {
        .category-item {
            position: relative;

            img {
                object-fit: cover;
                object-position: center;
                aspect-ratio: $aspect-ratio;
            }

            .badge-default {
                position: absolute;
                left: 10px;
                top: 0;
            }
        }
    }
}

.managers {
    .manager-card-body {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .manager-card-body-image {
            width: 100px;
            height: 100px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                overflow: hidden;
                border-radius: 50%;
            }
        }

        .manager-card-body-description {
            max-width: 350px;
            font-size: 14px;
        }
    }

    .manager-card-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
    }
}


.job-card-item {
    background-color: #f2f2f3;
    border-radius: 10px;

    .job-card-item-body {
        .title {
            font-size: 16px;
        }

        .description {
            font-size: 14px;
        }
    }
}

.advert-image {
    justify-content: center;
    display: flex;
    width: 100%;

    img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }
}

.profile-box {
    .profile-image {
        width: 120px;
        height: 120px;
        margin: 0 auto;

        img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            object-fit: cover;
        }
    }

    .profile-info {
        .profile-info-item {
            border-bottom: 1px solid $border-color;
            padding: 14px 0;

            span {
                font-size: 16px;
            }

            i {
                font-size: 22px;
            }

            .comment-icon {
                border: 2px solid;
                border-radius: 100%;
                width: 22px;
                height: 22px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: bold;
            }
        }
    }
}

.detail-section {
    .detail-body {
        p {
            font-size: 16px;
        }

        h2 {
            font-size: 36px;
        }

        img {
            max-width: 100% !important;
            height: auto !important;
        }
    }
}

.notes {
    .note-item {
        border-bottom: 1px solid $border-color;
        padding: 14px 0;
    }
}

.latest-comments {
    background-color: #F3F3F3;

    .latest-comments-header {
        font-size: 20px;
        background-color: #292662;
    }

    .latest-comments-body {
        font-size: 14px;

        h3 {
            font-size: 14px;
            text-decoration: underline;
            font-weight: bold;
            color: #292662;
        }

        .read-time {
            font-size: 10px;
        }

        i {
            font-size: 20px;
        }
    }
}

.btn-next-article {
    text-align: end;
}

.reviews {
    .latest-news-right-item-image {
        width: 118px;

        img {
            object-fit: cover;
            border-radius: 5px;
        }
    }

    p {
        font-size: 16px !important;
    }
}

.box-item {
    a {
        text-decoration: none;
        color: inherit;
    }

    .box-item-image {
        height: 250px;

        img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
            object-fit: cover;
        }
    }

    &.box-item-lg {
        .box-item-image {
            height: 430px;

        }
    }

    .box-item-body {
        .description {
            font-size: 18px;
        }
    }
}

.yellow-box {
    border-radius: 5px;

    .yellow-box-item {
        display: flex;

        i {
            font-size: 20px;
        }

        strong {
            font-size: 12px;
            text-decoration: underline;
        }

        .yellow-box-text {
            font-size: 14px;
        }
    }
}

.subscribe-box {
    background-color: $warning;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;

    .subscribe-box-header {
        background-color: $primary;
        color: white;
    }

    .subscribe-box-body {
        p {
            font-size: 16px;
        }
    }
}

.market-box {
    background-color: #f2f2f3;
    font-size: 12px;

    .market-title {
        font-weight: 600;
        margin-bottom: 10px;
    }

    .market-item {
        font-weight: 400;
        margin-bottom: 5px;
    }
}

.rate-box {
    .rate-box-body {
        background-color: #f2f2f3;

        select, input {
            width: 25%;
        }
    }

    .rate-box-header {
        border: 1px solid $border-color;
    }
}

@media (max-width: 991.98px) {
    .category-list {
        .category-item {
            img {
                width: 100%;
            }
        }
    }

    .managers {
        .manager-card-body-image {
            img {
                object-fit: scale-down;
            }
        }
    }

    .navbar {
        .navbar-toggler {
            margin-right: 20px;

            &:focus {
                box-shadow: none;
            }
        }

        .navbar-collapse {
            display: contents;

            img {
                width: 200px !important;
            }
        }
    }

    .detail-section {
        .sidebar-right {
            display: none;
        }

        .profile-box {
            .profile-image {
                margin: unset;
                width: 50px;
                height: 50px;
            }

            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .profile-info {
            display: flex;

            .profile-info-item {
                border-bottom: 0;
                margin-right: 10px;
            }

            span {
                display: none;
            }
        }
    }
}

/* width */
::-webkit-scrollbar {
    width: 8px;
    border-radius: 25px;
}

/* Track */
::-webkit-scrollbar-track {
    background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #28265E;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #28265E;
}

@media (max-width: 480px) {
    .navbar {
        .navbar-collapse {
            img {
                width: 100px !important;
            }
        }
    }
}
