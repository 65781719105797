div.video-embed {
    aspect-ratio: 16 / 9;
    border-radius: var(--video-embed-bdrs, 0.25em);
    display: grid;
    inline-size: 100%;
    position: relative;

    & i {
        place-self: center;
    }

    & button {
        background-color: var(--button-bgc, #F00);
        block-size: var(--button-h, 50px);
        border: 0;
        border-radius: var(--button-bdrs, 14%);
        display: grid;
        inline-size: var(--button-w, 75px);
        opacity: var(--button-op, 0.8);
        position: absolute;
        place-self: center;
        transition: all .2s ease-in;

        &::before {
            aspect-ratio: 1;
            background: #FFF;
            block-size: 1.5em;
            clip-path: polygon(20% 0%, 20% 100%, 100% 50%);
            content: '';
            place-self: center;
        }
    }

    :is(iframe, img) {
        block-size: 100%;
        border: 0;
        border-radius: inherit;
        inline-size: 100%;
        inset: 0;
        object-fit: cover;
        position: absolute;
    }

    & iframe:not([src=""]) + button {
        display: none;
    }

    &:hover button {
        --button-op: 1;
    }
}

div.video-embed[data-type=vimeo] button {
    --button-bgc: #00adef;
}



