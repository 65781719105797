.pagination {
    display: flex;
    justify-content: center;
    list-style: none;

    .page-item {
        margin: 0 2px;

        .page-link {
            padding: 6px 12px;
            text-decoration: none;
            border: 1px solid $secondary;
            color: #333;
            border-radius: 4px;
            transition: background-color 0.3s;
        }

        & a:hover {
            background-color: $secondary;
        }

        &.active .page-link {
            background-color: $primary;
            color: white;
        }
    }
}

