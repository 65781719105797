.auth-section-wrapper {
    background-color: $primary;
    height: 100vh;
    width: 100%;

    .auth-section {
        .auth-section-left {
            .auth-left-logo {
                width: 350px;
                margin: 0 auto;
            }
        }

        .auth-section-right {
            background-color: white;
            border-top-left-radius: 50px;
            border-bottom-left-radius: 50px;
            height: 100vh;
            width: 100%;
            display: flex;
            align-items: center;
            padding-left: 150px;

            .social-buttons {
                display: flex;
                align-items: center;

                .social-button-item {
                    border: 1px solid $border-color;
                    width: 220px;
                    padding: 10px 5px;
                    border-radius: 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 30px;
                        height: 30px;
                    }

                    span {
                        font-size: 13px;
                    }
                }
            }

            .or-text {
                font-size: 26px;
                text-align: center;
            }

            .form {
                input {
                    border-radius: 0 !important;
                    border: none;
                    border-bottom: 1px solid $border-color;
                }

                .info {
                    font-size: 16px;
                    text-align: center;
                }
            }
        }
    }
}

@media (max-width: 991.98px) {
    .auth-section-wrapper {
        .auth-left-logo {
            width: 290px !important;
        }

        .title {
            text-align: center;
            padding-top: 50px;
        }

        .social-buttons-not-mobile {
            display: none !important;
        }

        .social-buttons-mobile {
            display: block !important;

            .social-buttons {
                flex-direction: column;

                .social-button-item {
                    margin-right: 0 !important;
                    margin-bottom: 10px;
                }
            }
        }

        .auth-section-left {
            padding: 100px 0;
        }

        .auth-section-right {
            border-top-right-radius: 50px;
            border-bottom-left-radius: 0 !important;
            padding-left: 0 !important;
            justify-content: center !important;
        }
    }
}
