footer {
    background-color: #383838;
    color: white;

    .footer-links {
        display: flex;
        justify-content: end;
        align-items: center;
    }

    .footer-logo {
        width: 135px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .footer-top {
        .footer-top-links {
            .footer-top-item {
                margin-right: 30px;

                &:last-child {
                    margin-right: 0;
                }

                a {
                    text-decoration: none;
                    color: white;
                    font-size: 16px;
                }
            }
        }
    }

    .footer-bottom {
        border-top: 1px solid $border-color;

        .footer-bottom-text {
            font-size: 16px;
            font-weight: 200;
        }

        .footer-bottom-links {
            margin-right: 150px;

            .footer-title {
                font-size: 20px;
                font-weight: 500;
                margin-bottom: 2rem;
            }

            &:last-child {
                margin-right: 0;
            }

            p {
                font-weight: 200;
                font-size: 16px;
            }
        }
    }
}

@media (max-width: 991.98px) {
    footer {
        .footer-links {
            justify-content: space-between !important;

            .footer-bottom-links {
                margin-right: 46px !important;
            }
        }

        .footer-logo {
            margin: 0 auto;
        }

        .footer-top {
            .row {
                flex-direction: column;
            }

            .footer-top-links {
                display: none !important;
            }
        }

        .footer-bottom {
            border: none;

            .footer-bottom-text {
                margin-bottom: 30px;
                text-align: center;
            }
        }
    }
}
