header {
    .header-banner {
        width: 760px;
        height: auto;
        border-radius: 5px;
    }

    .navbar {
        background-color: $primary;
        box-shadow: 0 0 0.25rem rgba(0, 0, 0, .3);

        .navbar-brand {
            img {
                width: 157px;
            }
        }
    }

    .dropdown-menu {
        border-radius: 0;
        top: 4px;

        .dropdown-item:active {
            background-color: $primary;
        }
    }
}

.header-top-wrapper {
    background-color: $warning;
    position: relative;
    z-index: 1;

    .header-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: .5rem;

        a {
            text-decoration: none;
            color: black;
            font-size: 14px;
        }
    }
}

.header-bottom-wrapper {
    border-bottom: 1px solid #e4e4e4;

    .header-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .nav-item {
            padding: .25rem;

            .nav-link {
                padding: .5rem 1rem;
                border-radius: .375rem;
                transition: all .3s ease-in;
                box-shadow: none;

                &:hover {
                    color: $body-color;
                    background-color: $secondary;
                }

                &.active {
                    color: white;
                    background-color: $primary;
                }
            }
        }
    }
}

.search-icon {
    cursor: pointer;
}

.search-popup {
    position: fixed;
    width: 100%;
    z-index: 99;

    .card-body {
        text-align: center;

        .search-form {
            display: inline-block;
            min-width: 280px;

            .search-input {
                display: none;
                width: 0;
                transition: all .4s ease-in-out;

                &:focus {
                    box-shadow: none;
                    border-color: $primary !important;
                }
            }
        }
    }

    button {
        float: right;
        max-width: 2%
    }
}

@media (max-width: 991.98px) {
    .header-banner {
        display: none;
    }

    .header-top-wrapper {
        .header-top {
            a {
                font-size: 7px !important;
                margin-right: 2px !important;
            }
        }
    }

    .header-bottom-wrapper {
        .header-bottom {
            .header-bottom-item {
                padding: 10px 6px;
                margin-right: 3px;

                &.active {
                    background-color: unset !important;

                    a {
                        color: $primary !important;
                        font-weight: bold;
                    }
                }

                a {
                    font-size: 10px;
                }
            }
        }
    }
}
