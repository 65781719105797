@mixin title-bar {
    font-size: 1.75rem;
    font-weight: bold;
    line-height: 1.5;
    text-align: left;
    color: #ffffff;
    margin: 0;
    @include mq("mobile") {
        font-size: 1.5rem;
    }
}

@mixin add-gutter($size) {
    margin-right: -$size;
    margin-left: -$size;

    > [class*="col-"] {
        padding-right: $size;
        padding-left: $size;
        margin-bottom: $size * 2;
    }
}

@mixin line-clamp($line) {
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

// responsive mixin
@mixin mq($width: "mobile") {
    @if ($width == "mobile") {
        @media (max-width: 590px) {
            @content
        }
    } @else if ($width == "tablet") {
        @media (max-width: 992px) {
            @content
        }
    } @else if ($width == "large") {
        @media (max-width: 1200px) {
            @content
        }
    }
}

.line-clamp-2 {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
