.latest-news-sidebar {
    .latest-news-right-item {
        .latest-news-right-item-image {
            img {
                max-width: 100%;
                object-fit: cover;
                aspect-ratio: $aspect-ratio;
            }
        }

        .latest-news-right-item-text {
            font-size: 14px;
            max-width: 172px;

            .date {
                font-size: 10px;
            }

            .category {
                border-right: 1px solid $border-color;
                text-transform: uppercase;
            }
        }
    }
}


.latest-news-item-lg {
    .latest-news-item-lg-image {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            height: 100%;
            z-index: 1;
            background: linear-gradient(rgba(60, 57, 57, 0), #101010);
            opacity: 1;
            border-radius: 5px;
        }

        .image-content {
            position: absolute;
            bottom: 20px;
            left: 0;
            width: 100%;
            padding: 0 28px;
            z-index: 9;
            color: white;

            .badge {
                border-radius: 4px;
            }

            .user-image {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    aspect-ratio: $aspect-ratio;
                }
            }
        }
    }
}
