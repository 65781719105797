body {
    color: black;
    font-family: $font-family-sans;
}

a {
    text-decoration: none;
    color: inherit;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
    font-weight: 500;
    line-height: 1.25;
    font-family: $font-family-serif;
}

hr {
    border-color: var(--bs-gray-500);
}

hr.yellow {
    border-color: $warning;
}

.main > section {
    padding: 2rem 0;
}

.text-green {
    color: $green !important;
}

.text-primary {
    color: $primary !important;
}

.bg-green {
    background-color: $green !important;
    border-color: $green !important;
}

.bg-warning {
    background-color: $warning !important;
}

.bg-primary {
    background-color: $primary !important;
    color: white !important;
}

.bg-dark {
    background-color: $bg-dark !important;
    color: white !important;
}

.bg-darker {
    background-color: $bg-darker !important;
    color: white !important;
}

.text-danger {
    color: #FF184E;
    font-size: 16px;
    font-weight: 500;
}

.badge-default {
    span {
        padding: 10px 15px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        font-size: 13px;
        text-transform: uppercase;
    }
}

.badge-outline {
    span {
        padding: 10px 15px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        font-size: 13px;
        text-transform: uppercase;
        background: $bg-white;
        border: 1px solid $primary;
        color: $primary;
    }
}

.text-muted {
    color: #aaafb4 !important;
}

.btn-primary {
    background-color: #28265E;
    border-color: #28265E;

    &:focus {
        box-shadow: none;
        border-color: #28265E;
        background-color: #28265E;
    }

    &:hover {
        border-color: $warning;
        background-color: $warning;
    }
}

.btn-white {
    font-weight: bold;
    border: 1px solid #d9dcde;
    border-radius: 0;
    font-size: 14px;

    &:hover {
        border: 1px solid #1e1e1e;
    }
}


.btn-warning {
    background-color: $warning;
    border-color: $warning;

    &:focus {
        box-shadow: none;
        border-color: $warning;
        background-color: $warning;
    }

    &:hover {
        border-color: $warning;
        background-color: $warning;
    }
}

.btn-info {
    background-color: $info;
    border-color: $info;
    color: white !important;

    &:focus {
        box-shadow: none;
        border-color: $info;
        background-color: $info;
    }

    &:hover {
        border-color: $info;
        background-color: $info;
    }
}

.border-half {
    position: relative;

    &:after {
        content: "";
        position: absolute;
        height: calc( 100% - 1rem );
        top: 0;
        left: 50%;
        width: 1px;
        background: var(--bs-gray-500);
        opacity: 0.25;

        @include mq("mobile") {
            content: unset;
        }
    }
}

