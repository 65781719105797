.videos-section {
    color: white;
    background-color: #151D2B;

    .video-cover-image {
        width: 100%;
        height: unset;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            aspect-ratio: 16/9;
        }
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    .videos-title {
        position: relative;
        height: 2rem;
        .badge-default {
            position: absolute;
            top: -2rem;
        }
    }

    .news-list-right {
        .play-text {
            font-size: 22px;
        }

        .video-list-box {
            background-color: #161d25;

            .video-item {
                .video-item-image {
                    width: 75px;
                    box-sizing: content-box;
                    flex-shrink: 0;

                    img {
                        object-fit: cover;
                        width: 100%;
                        aspect-ratio: 16/9;
                        border-radius: 0;
                    }
                }

                .video-item-text {
                    font-size: 14px;;

                    a {
                        text-decoration: none;
                        color: inherit;
                    }
                }
            }

            .video-list-box-bottom {

            }
        }
    }
}

.video-list {
    .video-list-item {
        .title {
            margin: 0 auto;
            box-sizing: border-box;
            font-style: normal;
            font-weight: 700;
            font-size: 1.25rem;
            line-height: 1.25;
        }

        .video-list-item-footer {
            font-size: 16px;
        }

        .description {
            font-style: normal;
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.5;
            margin: 0;
        }

        img {
            border-radius: 0;
        }
    }
}

.play-icon-box {
    i {
        color: $danger;
        font-size: 32px;
    }

    span {
        font-size: 16px;
    }
}
