.news-bites {
    background-color: var(--bs-light);

    .news-bites-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .news-bites-body {

        .news-item {

            a {
                text-decoration: none;
                color: inherit;
            }

            .title {
                font-weight: 500;
                min-height: 2.8rem;
                line-height: 1.4;
                @include line-clamp(2);
                height: 3rem;
            }

            .date-day {
                font-size: 48px;
                color: #e8e8e8 !important;
                font-weight: bold;
            }
        }
    }
}

